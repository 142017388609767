var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-crono","visible":_vm.isAdd,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-add', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.isEdit ? 'Editar' : 'Agregar nuevo')+" cronograma")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(_vm.items)}}},[_c('validation-provider',{attrs:{"name":"docType","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Docuemnto","label-for":"docType"}},[_c('v-select',{attrs:{"id":"docType","label":"text","reduce":function (types) { return types.value; },"options":_vm.types,"placeholder":"Tipo de Documento","autocomplete":"off"},model:{value:(_vm.items.docType),callback:function ($$v) {_vm.$set(_vm.items, "docType", $$v)},expression:"items.docType"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"document","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Documento","label-for":"document"}},[_c('b-form-input',{attrs:{"id":"document","placeholder":"Documento","autocomplete":"off"},model:{value:(_vm.items.document),callback:function ($$v) {_vm.$set(_vm.items, "document", $$v)},expression:"items.document"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fullname","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre","label-for":"fullname"}},[_c('b-form-input',{attrs:{"id":"fullname","placeholder":"Nombre","autocomplete":"off"},model:{value:(_vm.items.fullname),callback:function ($$v) {_vm.$set(_vm.items, "fullname", $$v)},expression:"items.fullname"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"requeridoE|correo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"email","trim":"","placeholder":"Email","autocomplete":"off"},model:{value:(_vm.items.email),callback:function ($$v) {_vm.$set(_vm.items, "email", $$v)},expression:"items.email"}}),_c('small',{staticClass:"text-danger alert",style:({
                height: (errors.length > 0 ? 20 : 0) + 'px',
              })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.isEdit)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"password","trim":"","type":_vm.passwordFieldType,"name":"login-password","autocomplete":"off","placeholder":"Contraseña"},model:{value:(_vm.items.password),callback:function ($$v) {_vm.$set(_vm.items, "password", $$v)},expression:"items.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1):_vm._e(),(!_vm.isEdit)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"password","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"password","trim":"","type":_vm.passwordFieldType,"name":"login-password","autocomplete":"off","placeholder":"Contraseña"},model:{value:(_vm.items.password),callback:function ($$v) {_vm.$set(_vm.items, "password", $$v)},expression:"items.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger alert",style:({
                height: (errors.length > 0 ? 20 : 0) + 'px',
              })},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"position","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Posición","label-for":"position"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.positions,"label":"description","input-id":"position","reduce":function (positions) { return positions.description; },"placeholder":"Posición"},model:{value:(_vm.items.position),callback:function ($$v) {_vm.$set(_vm.items, "position", $$v)},expression:"items.position"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"role","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Rol","label-for":"role"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roles,"label":"description","input-id":"role","reduce":function (roles) { return roles.description; },"placeholder":"Rol"},model:{value:(_vm.items.role),callback:function ($$v) {_vm.$set(_vm.items, "role", $$v)},expression:"items.role"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"group","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Grupo","label-for":"group"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.grupos,"label":"description","input-id":"group","reduce":function (grupos) { return ((grupos.code) + "|" + (grupos.description)); },"placeholder":"Grupo","disabled":_vm.items.role == 2,"multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+"|"+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.groups),callback:function ($$v) {_vm.$set(_vm.items, "groups", $$v)},expression:"items.groups"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2 justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"disabled":invalid,"variant":"primary","type":"submit"}},[(_vm.isDisabled)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isDisabled)?_c('span',{staticClass:"px-1"},[_vm._v("guardando...")]):_c('span',[_vm._v(_vm._s(_vm.isEdit ? 'Actualizar' : 'Agregar'))])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }